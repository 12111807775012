(function ($, root, undefined) {

    $(function () {
			
            
            
            
			$('.bloc.avis').find('.play').magnificPopup({
                    type: 'inline',
                    mainClass: 'mfp-fade',
                    removalDelay: 160, 
                    preloader: false,
                    fixedContentPos: true,
                    overflowY: 'hidden' 
             });
            $('.salle-template-default main').find('.play').magnificPopup({
                    type: 'inline',
                    mainClass: 'mfp-fade',
                    removalDelay: 160, 
                    preloader: false,
                    fixedContentPos: true,
                    overflowY: 'hidden'
             });
            
            
            $('#liste_salle .salle_teaser').click(function(){
               window.location = $(this).find('a').first().attr('href'); 
            });
         
         
            if($('.bloc.avis .owl-carousel').length>0 && $(window).width()<992){
                $('.bloc.avis .owl-carousel').owlCarousel({
                        loop:true,
                        margin:0,
                        stagePadding: 50,
                        center:true,
                        nav:true,
                        responsive:{
                            0:{
                                items:1
                            },
                            600:{
                                items:2
                            }
                            
                        }
                    });
            }
  
            if($('.stickyjs').length>0){
                var bottomSpace = $('.bloc.newsletter').outerHeight() + $('.bloc.bandeau_contact').outerHeight() + $('.footer').outerHeight();
                $(".stickyjs").sticky({topSpacing:130,bottomSpacing:bottomSpace});
            }
            
            var listeSalle = $('#liste_salle');
            if (listeSalle.hasClass('is-scrollable')) {
//                $('html, body').scrollTop(listeSalle.offset().top - 70);
//                $(window).scrollTop(listeSalle.offset().top - 70);
                $('html, body').animate({ scrollTop: listeSalle.offset().top - 130 }, 1000 ); // Go
//                $(window).animate({ scrollTop: listeSalle.offset().top - 70 }, 1000 ); // Go
            }
        
            // Bandeau
            var messageBandeau = $('#message-bandeau');
            if (messageBandeau.length) {                
                $(window).on('load', function(){
                    messageBandeau.addClass('is-active');
                });
            }
            if ($('body').hasClass('template-book')) {
                $('html').removeClass('has-bandeau');
            }
            
    });

})(jQuery, this);